<template>
  <div v-if="byteCode" id="nav-transaction_code" class="tab-pane fade show" role="tabpanel" aria-labelledby="nav-transaction_code-tab">
    <contract-code-tabs />
    <div class="tab-content">
        <div id="nav-source_code" class="tab-pane fade show active" role="tabpanel" aria-labelledby="nav-source_code-tab">
            <div class="card">
                <div v-if="contractDetails.source_code_verified == 0 && statusContractVerify == 0" class="d-flex justify-content-center align-items-center">
                  <div class="jumbotron m-1">
                     <div><code>sha256: {{contractDetails?.contractDecoded?.c_hash}}</code></div>
                     <div class="text-center">
                        <span class="lead text-center fs-6">Get your badge. Submit your contract source code </span>
                        <span v-if="!contractDetails.source_code_verified" class="badge bg-secondary text-center">Not verified <i class="bi bi-patch-check"></i></span>
                        <span v-else class="badge bg-success text-center">Verified <i class="bi bi-patch-check-fill"></i></span>
                        <span>
                            <form class="bg-secondary-subtle not-verified rounded" @submit.prevent="submit"> 
                                <div class="p-1 m-1 text-center">
                                    <div class="">
                                        <input ref="file" type="file" accept=".zip" name="source_code" class="btn btn-sm" @change="uploadFile"/>
                                        <button :disabled="!fileSelected" class="btn btn-primary btn-sm" @click="uploadContract">Verify source code</button>
                                    </div>
                                    <div v-if="loadingContractVerify" class="spinner-border text-warning m-1" role="status">
                                      <span class="sr-only"></span>
                                    </div>
                                </div>
                            </form>
                        </span>
                    </div>
                  </div>
                </div>
                <div v-if="contractDetails.source_code_verified == 1 || statusContractVerify == 1" class="d-flex justify-content-center align-items-center card">
                   <div class="jumbotron m-5">
                   <div class="text-center">
                        <span class="badge bg-warning text-center fs-6">Pending verification <i class="bi bi-patch-check-fill"></i></span>
                   </div>
                   <p class="lead text-center">Source contract verification in progress.</p>
                   <div class="progress">
                        <div class="progress-bar progress-bar-striped progress-bar-animated" 
                            role="progressbar" 
                            aria-valuenow="75" 
                            aria-valuemin="0" 
                            aria-valuemax="100" 
                            style="width: 100%">
                        </div>
                   </div>
                   </div>
                </div>
                <div v-if="contractDetails.source_code_verified == 2 || statusContractVerify == 2">
                   <div class="jumbotron m-5">
                   <div class="text-center">
                        <span class="badge bg-success text-center fs-6"> Verified <i class="bi bi-patch-check-fill"></i></span>
                   </div>
                   <p class="lead text-center">Contract verified.</p>
                   <p class="lead text-center">
                      <a target="_blank" :href="'https://vscode.dev/github/fedotser/' + contractDetails.contract_id">
                         View source code
                      </a>
                      or
                      <a target="_blank" :href="'https://github.com/fedotser/' + contractDetails.contract_id">
                         check git repository.
                      </a>
                   </p>
                   </div>
                </div>
            </div>
            <codemirror v-model="transactionRustV" placeholder="Code goes here..." :style="{height: '400px', fontSize:'12px', borderRadius: '5px', padding: '2px'}" :autofocus="false"
              :indent-with-tab="true" :tab-size="2" :fold-gutter="true"
              :gutters="['CodeMirror-linenumbers', 'CodeMirror-foldgutter']" :extensions="extensions" />
            <button class="btn btn-primary w-100" @click="downloadUint8ArrayAsFile"> <small> Download Rust Version</small> </button>
        </div>
        <div id="nav-webassembly_code" class="tab-pane fade show" role="tabpanel" aria-labelledby="nav-webassembly_code-tab">
            <codemirror v-model="byteCodeProp" placeholder="Code goes here..." :style="{height: '400px', fontSize: '12px', borderRadius: '5px', padding: '2px'}" :autofocus="false"
              :indent-with-tab="true" :tab-size="2" :fold-gutter="true"
              :gutters="['CodeMirror-linenumbers', 'CodeMirror-foldgutter']" :extensions="extensions" />
            <button class="btn btn-primary w-100" @click="downloadUint8ArrayAsFile"> <small> Download binary </small> </button>
        </div>
        <div id="nav-contract_specs" class="tab-pane fade show" role="tabpanel" aria-labelledby="nav-contract_specs-tab">
          <div class="card">
          <div class="m-2"><small>Contract specs:</small></div>
            <div id="accordion p-1">
              <div v-for="(specEntry, index) in wasmParsedJson" :key="index + specEntry.name" class="">
                <div :id="specEntry.name" class="card-header p-0">
                  <div class="mb-0">
                    <span class="p-2" data-bs-toggle="collapse" :data-bs-target="'#collapse-'+specEntry.name" aria-expanded="true" :aria-controls="'collapse-' + specEntry.name">
                      <small><i class="bi bi-arrow-right"></i> {{specEntry.name}} </small>
                    </span>
                  </div>
                </div>
                <div :id="'collapse-'+specEntry.name" class="collapse" :aria-labelledby="specEntry.name" data-parent="#accordion">
                  <div class="card-body">
                    <div>{{specEntry.doc || 'No description'}} </div>
                    <hr/>
                    <div v-if="specEntry.inputs">
                        <strong>Inputs</strong>: 
                        <vue-json-pretty show-icon :data="JSON.parse(specEntry.inputs)" />
                    </div> 
                    <div v-if="specEntry.outputs">
                        <strong>Outputs</strong>: 
                        <vue-json-pretty show-icon :data="JSON.parse(specEntry.outputs)" />
                    </div> 
                    <div v-if="specEntry.fields">
                        <strong>Fields</strong>: 
                        <vue-json-pretty show-icon :data="JSON.parse(specEntry.fields)" />
                    </div>
                    <div v-if="specEntry.lib">
                        <strong>Libs</strong>: 
                        <vue-json-pretty show-icon :data="specEntry.lib" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
  <div id="nav-transaction_events" class="tab-pane fade show" role="tabpanel" aria-labelledby="nav-transaction_events-tab">
      <div v-if="showEvents?.events?.length" class="card p-2 mb-1">
        <div v-for="(event, key) in showEvents.events" :key="key">
          <div>
            <span> Topics </span>
            <div v-for="(topic, key) in event.topics" :key="key">
                <span class="text-capitalize"><kbd>{{ topic }}</kbd></span>
            </div>
          </div>
          <div>Data: <kbd> {{event.data}} </kbd> </div>
        </div>
      </div>
      <div v-else> <no-content/> </div>
  </div>

  <div id="nav-transaction_state" class="tab-pane fade show" role="tabpanel" aria-labelledby="nav-transaction_state-tab">
    <div v-if="parsedDifferences.after?.added?.length || parsedDifferences.before?.added?.length" class="card p-2">
      <div class="card-header">{{ $t('txn_details.state_created') }} {{ $t('txn_details.state_properties') }}</div>
      <div class="card-body">
        <div v-for="prop in parsedDifferences.after.added" :key="prop">
          {{ prop.rhs.switch().name }} <kbd> {{ prop.rhs.value() }} </kbd>
        </div>
        <div v-for="prop in parsedDifferences.before.added" :key="prop">
          {{ prop.rhs.switch().name }} <kbd> {{ prop.rhs.value() }} </kbd>
        </div>
      </div>
    </div>

    <div v-if="parsedDifferences.after?.new?.length || parsedDifferences.before?.new?.length" class="card p-2">
      <div class="card-header">{{ $t('txn_details.state_added') }} {{ $t('txn_details.state_properties') }}</div>
      <div class="card-body">
        <div v-for="prop in parsedDifferences.after.new" :key="prop" class="property"><kbd>{{ prop }}</kbd></div>
        <div v-for="prop in parsedDifferences.before.new" :key="prop" class="property"><kbd>{{ prop }}</kbd></div>
      </div>
    </div>

    <div v-if="parsedDifferences.after?.edited?.length || parsedDifferences.before?.edited?.length" class="card p-2">
      <div class="card-header">{{ $t('txn_details.state_updated') }} {{ $t('txn_details.state_properties') }}</div>
      <div class="card-body">
        <div v-for="prop in parsedDifferences.after.edited" :key="prop.key" class="property">
          <span class="change">{{ transformKey(prop.key) }}: </span>
          <kbd> {{ prop.before }} </kbd> &#8594; <kbd> {{ prop.after }}</kbd>
        </div>
        <hr />
        <div v-for="prop in parsedDifferences.before?.edited" :key="prop.key" class="property">
          <span class="change">{{ transformKey(prop.key) }}: </span>
          <kbd> {{ prop.before }} </kbd> &#8594; <kbd> {{ prop.after }} </kbd>
        </div>
      </div>
    </div>

    <div v-if="parsedDifferences.after?.deleted?.length || parsedDifferences.before?.deleted?.length" class="card p-2">
      <div class="card-header">{{ $t('txn_details.state_removed') }} {{ $t('txn_details.state_properties') }}</div>
      <div class="card-body">
        <div v-for="prop in parsedDifferences.after.deleted" :key="prop" class="property">{{ prop }}</div>
        <div v-for="prop in parsedDifferences.before.deleted" :key="prop" class="property">{{ prop }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, ref} from "vue";
import {useI18n} from "vue-i18n";
import {Codemirror} from 'vue-codemirror'
import {wast} from '@codemirror/lang-wast'
import {rust} from '@codemirror/lang-rust'
import http from '@/utils/axios';
import {oneDark, oneDarkTheme} from '@codemirror/theme-one-dark'
import deepDiff from 'deep-diff';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import { useStore } from "vuex";
import NoContent from "@/components/NoContent.vue";
import ContractCodeTabs from "@/components/stats/ContractCodeTabs.vue";

export default defineComponent({
  name: "TransactionMeta",
  components: {
    ContractCodeTabs,
    Codemirror,
    VueJsonPretty,
    NoContent,
  },
  props: {
    byteCode: String,
    showEvents: Object,
    parsedWasm: Object,
    wasmCode: Object,
    stateChanges: Object,
    txnDetails: Object,
    contractDetails: Object,
    BigInt: Object
  },
  setup(props) {
    BigInt.prototype.toJSON = function () {return this.toString()}
    const {t} = useI18n();
    const store = useStore();
    let sourceZip = ref();
    let rustCode = ref();
    let fileSelected = ref(false)
    const byteCodeProp = computed(() => {
      if (props.byteCode) {
        return props.byteCode;
      }
      return t("error.no_data")
    });


    const fetchRustCode = () => {
        const cdnSpaces = http.create({
            timeout: 50000,
            baseURL: process.env.VUE_APP_SPACES_CDN
        })
        cdnSpaces.get(props.contractDetails.rust_url)
            .then(res => {
              rustCode.value = res.data;
         })
    };

    const transactionRustV  = computed(() => {
          fetchRustCode()
          return rustCode.value;
    });

    const wasmCodeProp = computed(() => {
      if (props.wasmCode) {
        const hexString = Array.from(props.wasmCode).map(byte => byte.toString(16).padStart(2, '0')).join('').toUpperCase();
        const groupedBy4 = hexString.match(/.{1,4}/g).join(' ');
        const formattedHexString = groupedBy4.match(/.{1,40}/g).join('\n');
        return formattedHexString
      }
      return t("error.no_data")
    });
    const parseDeepDiff = (differences) => {
      const parsedDiffs = {
        added: [],
        new: [],
        edited: [],
        deleted: []
      };
      if (differences === undefined) {
        return parsedDiffs;
      }
      differences.forEach(diff => {
        if (diff.kind === 'A') {
          parsedDiffs.added.push(diff.item);
        } else if (diff.kind === 'N') {
          parsedDiffs.new.push(diff.rhs);
        } else if (diff.kind === 'E') {
          parsedDiffs.edited.push({
            key: diff.path.join('.'),
            before: diff.lhs,
            after: diff.rhs
          });
        } else if (diff.kind === 'D') {
          parsedDiffs.deleted.push(diff.lhs);
        }
      });

      return parsedDiffs;
    }

    const transformKey = (key) => {
      const words = key.split(/[_.]/);
      const transformedWords = words
        .slice(-3) // Keep the last two keys
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
      return transformedWords;
    }

    const downloadUint8ArrayAsFile = () => {
        const blob = new Blob([props.wasmCode], { type: 'application/octet-stream' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = props.txnDetails.contract_id + '_contract.wasm'; // Default file name if not provided
        a.style.display = 'none';

        document.body.appendChild(a);
        a.click();

          // Clean up
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }


    const parsedDifferences = computed(() => {
      if (props.stateChanges) {
        let changes = deepDiff.diff(props.stateChanges.before[0], props.stateChanges.before[1]);
        let changesAfter = deepDiff.diff(props.stateChanges.after[0], props.stateChanges.after[1]);
        let differencesAfter = parseDeepDiff(changes);
        let differencesBefore = parseDeepDiff(changesAfter);

        return {after: differencesAfter, before: differencesBefore};
      }
      return t("error.no_data")
    });
        
    const extensions = [oneDark, rust()]

    const wasmParsedJson = computed(() => {
      if (props?.contractDetails?.contractDecoded?.env?.specs) {
        return props?.contractDetails?.contractDecoded?.env?.specs;
      }
      return t("error.no_data")
    });

    const networkType = computed(
      () => store.getters["settings/getNetworkType"]
    );

    const uploadFile = (event) => {
       sourceZip = event.target.files[0];
       fileSelected.value = true;
    }
    const loadingContractVerify = computed(() => store.getters["contracts/getLoadingContractVerifyStatus"]);
    const statusContractVerify = computed(() => store.getters["contracts/getStatusContractVerify"]);
    const uploadContract = () => {
        store.dispatch("contracts/uploadContractSourceZip", {contractId: props.txnDetails.contract_id, sourceZip: sourceZip});
        const statusCheckInterval = setInterval(() => {
            store.dispatch("contracts/checkStatusContractSource", { contractId: props.txnDetails.contract_id });
            if (statusContractVerify.value !== 1) {
                clearInterval(statusCheckInterval);
            }
        }, 10000);
    }

    return {
            t, 
            byteCodeProp, 
            transactionRustV,
            wasmCodeProp, 
            extensions, 
            parsedDifferences, 
            transformKey, 
            downloadUint8ArrayAsFile, 
            wasmParsedJson, 
            networkType, 
            uploadContract, 
            uploadFile, 
            fileSelected, 
            loadingContractVerify, 
            statusContractVerify
        };
    },
});
</script>
