<template>
    <div id="pills-tab" class="btn-group nav-pills mt-1 mb-1 w-100 d-flex flex-row" role="tablist">
      <template
          v-for="(tab, idx) in navTabs"
          :key="idx"
       >
        <button 
          v-if="tab.visible" 
          class="nav-item"
          :class="`btn border bg-body text-decoration-none ${tab.name == currTab ? 'active text-primary' : ''} w-100`"
          data-bs-toggle="tab" 
          :data-bs-target="`#nav-${tab.name}`"
          type="button"
          role="tab" 
          :aria-controls="`nav-${tab.name}-tab`"
          aria-selected="false"
          @click="clickButton(tab)"
        >
          <i :class="`bi ${tab.icon}`"></i>
          <span class="d-none d-xl-inline d-xxl-inline ms-1">
            {{$t(`txn_details.${tab.name}`)}}
          </span>
        </button>
      </template>
    </div>
</template>

<script>
import { defineComponent, ref} from "vue";
export default defineComponent({
  name: 'ContractAndTransactionDetailsTabs',
  props: {
    contractPage: Boolean
  },
  setup(props) {
    const currTab = ref("operations");
    const navTabs = [
      { name: "operations", icon: "bi-view-list", active: !props.contractPage, visible : true},
      { name: "transactions", icon: "bi-view-list", active: props.contractPage, visible : props.contractPage},
      { name: "transaction_code", icon: "bi-file-earmark-code", active: false, visible: props.contractPage},
      { name: "transaction_events", icon: "bi-list-ul", active: false, visible : true},
      { name: "transaction_state", icon: "bi-arrow-right-circle-fill", active: false, visible : true},
      { name: "transaction_xdrs", icon: "bi bi-journal-code", active: false, visible : true},
    ];

    const clickButton = (tab) => {
      currTab.value = tab.name;
    }

    return {navTabs, currTab, clickButton}
  }
});

</script>
